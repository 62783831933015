import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"

export const updateProfile = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `updateUserProfile`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const uploadFile = data => {
    const updatedHeaders = {
        ...headers,
        Authorization: localStorage.getItem("authToken"),
        'Content-Type': 'multipart/form-data',
    }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `profile/file-upload`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const getBinaryData = (params) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST + "read-binary", { headers: updatedHeaders, params })
            if (response.status === 200) {
                const byteArray = new Uint8Array(response.data.data.binaryData.data)
                const blob = new Blob([byteArray], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                resolve(imageUrl)
            }
            reject(response)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}