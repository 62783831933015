import { createSlice } from "@reduxjs/toolkit";

const initialState = ''

const teethHealthSlice = createSlice({
    name: 'teethHealth',
    initialState,
    reducers: {
        storeHealth: (state, action) => {
            return state = action.payload
        },
        clearHealth: (state, action) => {
            return initialState
        }
    }

})

export default teethHealthSlice.reducer
export const { storeHealth, clearHealth } = teethHealthSlice.actions