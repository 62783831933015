import back from "../../assets/Icons/arrow-back.png"

const BackButton = (props) => {
    const { onClick } = props
    return (
        <div>
            <button type="button" className="m-3" onClick={onClick}>
                <img src={back} alt="back buttton" className=" h-6" />
            </button>

        </div>

    )
}

export default BackButton