import video from "../../assets/video/rotate-edited.mp4"

const Landscape = () => {

    return (
        <div className=" bg-black h-full w-full flex justify-center items-center">
            <video width="200" autoPlay loop muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <p className=" text-white">Rotate your phone to <br /> portrait mode.</p>
        </div>
    )
}

export default Landscape