import { GetBrowserDetails, getDesktopOrMobile } from "./deviceDetails"
import { GetMobileOperatingSystem } from "./getOS"

export const getCompleteDeviceDetails = () => {
    return {
        deviceType: getDesktopOrMobile() ? "Desktop" : "Mobile",
        os: GetMobileOperatingSystem(),
        model : navigator.userAgent,
        browser: JSON.stringify(GetBrowserDetails()),
        language: navigator.language,
    }
}

