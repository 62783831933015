import { createSlice } from "@reduxjs/toolkit"
import { generateRandomAlphaNumeric } from "../Utils/alphaNumeric"

const initialState = {
    imageFile: {},
    quickScanS3Folder: '',
    tpScanS3Folder:''
}

const quickScanSlice = createSlice({
    name: "quickScan",
    initialState,
    reducers: {
        clearQuickScan: () => {
            return initialState
        },
        addQuickScan: (state, action) => {
            state.imageFile = action.payload
        },
        createQuickScanS3Folder: (state) => {
            const randomString = generateRandomAlphaNumeric(2);
            const timestamp = Date.now().toString();
            const randomString2 = generateRandomAlphaNumeric(1);
            state.quickScanS3Folder = randomString + timestamp + randomString2
        },
        clearQuickScanFolder: (state) => {
            state.quickScanS3Folder = ''
        }


    }

})

export default quickScanSlice.reducer
export const { clearQuickScan, addQuickScan, createQuickScanS3Folder, clearQuickScanFolder } = quickScanSlice.actions